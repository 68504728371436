<template>
  <div class="ignore-box">
    <p class="time">發佈日期：2022/1/24</p>
    <p>
      在眾多星體的運行之中，
      2022年的名字是芙女星，亦相等於這年由芙女星掌控整年的禍福，這星特點就是威武及凶悍，且配合風水的五黃病禍凶星入中，大可估計芙女星將於2022年帶來的天災人禍非輕。這個絕對不能輕視！
    </p>
    <p>
      芙女星每在值年時都會遇上十二個不同的星體，而產生不一樣的催化，使得2022年出現獨特的風格，明顯跟過去兩年大不相同。
    </p>
    <p>首先，芙女星將會造成極大破壞的月份有四個月。「2、5、8及11月」</p>
    <p>
      更匪夷所思的，於這四個月中的風水組合都出現極度的不協調，凶星遇上大凶星，又或兩顆相同的星體同臨一地，各種因素使這四個月的破壞威力加倍。因此，縱然大家本來是在吉星體照耀下，若果沒有風水的助力，相信或多或少也會受到這股力量影響。
    </p>
    <p class="ignore-label">
      <span>2月份</span>
      是芙女星降臨的首月，由於年芙女星與月芙女星在此刻相遇，代表兩大極其凶悍的星體開戰，災禍當然是威力無比！
    </p>
    <p class="ignore-label">
      <span>「中宮」</span>
      是這月最凶險的方位，五黃星加配二黑星，使得病禍威力加強，就像十號風球一樣，將芙女星的兇猛面孔盡顯無遺。
    </p>
    <p class="ignore-label">
      <span>「東北方」</span>
      是繼中宮後的凶位，雖然東北方是2022年的財位，但月星五黃的排名也屬凶星首三名，這月與財位相交，大大削弱了財星的光芒。
    </p>
    <p class="ignore-label">
      <span>「西北方」</span>
      也要多注意，善戰的六白星遇到是非小人三碧星，自然話不投機一較高下，把這個方位的戰鬥氣場散發，弄得永無寧日。
    </p>
    <p class="ignore-label">
      <span>5月份</span>
      是芙女星碰上天鴿星的時段，刑害重疊，傷害嚴重。
    </p>
    <p class="ignore-label">
      <span>「西南方」</span>
      仍然屬於不吉的方位；也現二五交加，禍害加劇。
    </p>
    <p class="ignore-label">
      <span>「正東方」</span>
      是次凶位；同樣是三碧星碰上六白星，金木交戰；憤怒的能量籠罩着這方位。
    </p>
    <p class="ignore-label">
      <span>「東北方」</span>
      財位同樣巧遇二黑星，也把八白財星的能量壓下去，使得這個吉星無法發揮！
    </p>
    <p class="ignore-label">
      自古有云：「太歲頭上不可動土」，代表着太歲星本來就是擁有掌管當年禍福的一股力量，禍福，亦因為古人經驗累積，對太歲星敬而畏之；代代相傳，直至今日，中國人傳統上仍然相信去廟宇攝太歲或在家中放置太歲吉祥物，都可達至趨吉避凶的成效。2022可以擺放吉祥小金豬化解芙女星當值太歲。
    </p>
  </div>
</template>

<script>
export default {
  name: "Ignore",
};
</script>

<style lang="scss" scoped>
.ignore-box {
  padding: 0.4rem;
  font-size: 0.28rem;
  line-height: 0.44rem;
  color: #988157;
  text-align: justify;

  .time {
    text-align: right;
    margin-bottom: 0.2rem;
    color: #c5c5c5;
  }

  .ignore-label {
    margin-top: 0.48rem;
    & > span {
      background: #e6dcc9;
      color: #6b4e16;
      padding: 0.04rem 0.14rem;
      font-weight: 600;
    }
  }
}
</style>
